*,
*::before,
*::after {
	margin: 0;
	padding: 0;
}

html,
body {
	block-size: 100dvh;
	overflow: hidden;
}

#root {
	position: relative;
	inline-size: 100%;
	block-size: 100%;
}

a {
	display: none;
}
